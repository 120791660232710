




































































































































































































/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/member-ordering */

import { Component, Vue, Watch } from 'vue-property-decorator';
import globalFooter from '@/components/GlobalFooter/index.vue';
import loginDropdown from '@/components/loginDropdown/index.vue';
import { userModule } from '@/store/modules/user';
import { permissionModule } from '@/store/modules/permission';

@Component({
  components: {
    globalFooter,
    loginDropdown,
  },
})
export default class Home extends Vue {
  tabIndex = 0;
  TLBS = (window as any).TLBS;
  allLoginList = [
    {
      value: '进入系统',
      label: '/system',
    },
    {
      value: '退出',
      label: '/logout',
    },
  ];
  loginList: any = [];
  loginTextList = {
    login: '进入系统',
    notLogin: '立即登录',
  };
  loginText = this.loginTextList.notLogin;
  get menuLists(): string {
    return JSON.stringify(permissionModule.routes);
  }
  async mounted(): Promise<void> {
    this.loginTextHandler();
  }
  buriedPointHandler(buriedPoint: string, params?: any): void {
    this.$beaconAction.onUserAction(buriedPoint, params);
  }
  handleHover(index: number): void {
    this.tabIndex = index;
  }
  loginClickHandler(): void {
    const { login, notLogin } = this.loginTextList;
    if (this.loginText === login) {
      const path2 = sessionStorage.getItem('systemCurrentPath');
      const path3 = sessionStorage.getItem('initSystemPath');
      let paths = path2 ? path2 : path3;
      if (!paths) paths = '/system';
      if (!this.menuLists.includes(paths)) {
        paths = '/system';
      }
      this.$router.push(paths);
      this.buriedPointHandler('user_login', {
        date: this.$dayjs().format('YYYY-MM-DD hh:mm:ss'),
      });
    } else if (this.loginText === notLogin) {
      this.TLBS.component.initComponent(this.loginInit());
      // 上报点击登录按钮
      this.buriedPointHandler('click_login', {
        date: this.$dayjs().format('YYYY-MM-DD hh:mm:ss'),
      });
    }
  }
  successFun(status: string): void {
    localStorage.setItem('loginStatus', status);
  }
  loginInit(): void {
    const redirectParam = {
      url: 'https://test-mall.innovation.qq.com/mall/index',
      urlText: '返回首页',
      text: '已注册成功',
      hideLoginOption: ['WeChatOfficialAccount'],
    };
    this.TLBS.component.login.init(redirectParam);
    this.TLBS.component.login.show(this.successFun('1'));
    // 上报点击登录按钮
    this.buriedPointHandler('login_success', {
      date: this.$dayjs().format('YYYY-MM-DD hh:mm:ss'),
    });
  }

  loginTextHandler(): void {
    this.successFun('0');
    const { token, mid } = userModule;
    const { login, notLogin } = this.loginTextList;
    if (token && mid) {
      this.loginList = this.allLoginList;
      this.loginText = login;
    } else {
      const [, b] = this.allLoginList;
      this.loginList[0] = b;
      this.loginText = notLogin;
    }
  }

  @Watch('$store.state.user.token')
  changeUserModule(): void {
    this.loginTextHandler();
  }


  // 是否禁止访问
  isForbidden = false;
  // 在组件的 created 钩子中监听自定义事件
  handleIsForbiddenChange(isForbidden: boolean) {
    this.isForbidden = isForbidden;
    if (this.isForbidden) {
      this.$message.error('不可在当前地区访问');
      setTimeout(() => {
        this.logoutFun();
      }, 3000);
    }
  }
  logoutFun() {
    userModule.LogOut();
    sessionStorage.clear();
    this.TLBS.component.login.exit();
  }
  created() {
    this.checkIp();
  }

  private getIpOnlyOne = true;
  checkIp() {
    if (this.getIpOnlyOne) {
      this.getIpOnlyOne = false;
      (window as any).ipCallback = function (res: any) {
        if (res.status === 0) {
          const adInfo = res.result.ad_info;
          if (adInfo.nation === '中国' && ['澳门特别行政区', '台湾省'].includes(adInfo.province)) {
            // 定位获取失败禁止访问
            updateForbidden(true);
          }
        } else {
          updateForbidden(true);
        }
      };
      // 更新是否可访问状态
      const updateForbidden = (status: boolean) => {
        this.handleIsForbiddenChange(status);
      };
      // ** key值 需要替换对应系统的key **
      const requestURL = 'https://apis.map.qq.com/ws/location/v1/ip?key=OLJBZ-QCG3H-4FMDT-WSUG6-7M63T-Z7B3A&output=jsonp&callback=ipCallback';
      const script = document.createElement('script');
      script.src = requestURL;
      document.body.appendChild(script);
    }
  }
}
